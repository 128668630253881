<template>
  <vs-card>
    <div slot="header" class="flex items-center px-5 justify-between">
      <h4>Create Organisation</h4>
      <vs-button
        @click="createOrganization"
        :disabled="isButtonDisabled"
      >
        Create
      </vs-button>
    </div>
    <vs-form autocomplete="off">
      <div class="w-1/2 p-5">
        <div>
          <h5>Company Info</h5>
        </div>

        <div class="w-full my-5">
          <vs-input
            :success="!errors.first('name') && organization.name != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('name') ? true : false"
            v-validate="'required|max:150'"
            name="name"
            data-vv-as="name"
            label="Organization Name"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('name')"
            v-model="organization.name"
          />
        </div>

        <div class="w-full my-5" v-if="$isAuRegion()">
          <vs-input
            :success="!errors.first('abn') && organization.abn != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('abn') ? true : false"
            v-validate="{
              required: true,
              regex: /^\d{11}$/,
            }"
            name="abn"
            :danger-text="errors.first('abn')"
            data-vv-as="abn"
            label="ABN"
            icon-no-border
            class="w-full"
            v-model="organization.abn"
          />
        </div>

        <div>
          <h5>Organisation Settings</h5>
        </div>

        <div>
          <p>
            Organisation settings are applied to all clinic workspaces
            associated to this Organisation. These can be changed at a clinic
            level from clinic settings.
          </p>
        </div>

        <div class="w-full my-5">
          <vs-input
            :success="!errors.first('email') && organization.email != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('email') ? true : false"
            v-validate="{
              required: true,
              regex: /(^\w.*@\w+\.\w)/,
            }"
            name="email"
            data-vv-as="email"
            label="Monthly Treatment Invoice Email(s)"
            class="w-full"
            :danger-text="errors.first('email')"
            v-model="organization.email"
          />
        </div>

        <div class="w-full my-5">
          <vs-input
            :success="
              !errors.first('consultationFee') &&
              organization.consultationFee != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('consultationFee') ? true : false"
            v-validate="{
              required: true,
              regex: /^\d*\.?\d*$/,
            }"
            name="consultationFee"
            :danger-text="errors.first('consultationFee')"
            data-vv-as="consultation fee"
            label="Treatment Consultation Fee (default is $25.00)"
            icon-no-border
            class="w-full"
            v-model="organization.consultationFee"
          />
        </div>
        <div class="w-full my-5" >
          <label>Enable/Disable BYO Organization</label>
          <vs-switch
            name="isByo"
            data-vv-as="BYO Organization"
            label="Enable/Disable BYO Organization"
            v-model="organization.isByo" />
        </div>
      </div>
    </vs-form>
  </vs-card>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import InvoiceVue from "../../pages/Invoice.vue";

const dict = {
  custom: {
    consultationFee: {
      regex: "The consultation fee field must be a number",
    },
    email: {
      regex: "The Monthly Treatment Invoice Email must be a valid email"
    }
  },
};
Validator.localize("en", dict);

export default {
  data: () => ({
    organization: {
      name: "",
      abn: undefined,
      email: "",
      consultationFee: 25.0,
      isByo: false,
    },
    isButtonDisabled: false,
  }),
  methods: {
    ...mapActions("organization", ["createOrganizationBasic"]),

    async createOrganization() {
      let isValid = await this.$validator.validate();
      let user = this.$store.state.AppActiveUser.userRole;

      if (isValid) {
        this.isButtonDisabled = true;

        let payload = {
          name: this.organization.name,
          abn: this.organization.abn,
          monthTreatmentInvoiceEmail: this.organization.email,
          consultationFee: this.organization.consultationFee,
          isByo: this.organization.isByo
        };
        try {
          const res = await this.createOrganizationBasic(payload);
          this.$vs.notify({
            title: "Organization Created",
            text: "Organization created successfully.",
            color: "success",
          });
          if (user === "superAdmin") {
            this.$router.push("/super-admin/organizations");
          } else {
            this.$router.push("/" + user + "/organizations");
          }

          this.isButtonDisabled = false;
        } catch (err) {
          this.isButtonDisabled = false;
          isValid = false;

          if (err.status === 422) {
            this.mapServerErrors(this, err);
            this.$vs.notify({
              title: "Failed",
              text: "Failed to create organization. Try again!",
              color: "danger",
            });
          }
          if (err.status === 409) {
            this.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
            this.errors.add({
              field: "email",
              msg: err.data.message,
            });
          }
        }
      }
    },
    validateEmail(email) {
      return /(^\w.*@\w+\.\w)/.test(email);
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    //   async getOrganizationDetails(id) {
    //     try {
    //       this.$vs.loading();
    //       const res = await this.fetchOrganizationDetails(id);
    //       this.$vs.loading.close();
    //       console.log(res.data.data)

    //     } catch (error) {
    //       console.log(error)

    //     }
    //   },
    // },
    // async created() {
    //   // this.dataTableParams.activeClinicId = await this.$store.state
    //   //   .AppActiveClinicId;
    //   // if (this.franchiseId && this.franchiseId !== "") {
    //   //   this.dataTableParams.franchiseId = this.franchiseId;
    //   // }
    //   this.getOrganizationDetails(this.$route.params.organizationId);
  },
};
</script>
