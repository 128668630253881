var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-card',[_c('div',{staticClass:"flex items-center px-5 justify-between",attrs:{"slot":"header"},slot:"header"},[_c('h4',[_vm._v("Create Organisation")]),_c('vs-button',{attrs:{"disabled":_vm.isButtonDisabled},on:{"click":_vm.createOrganization}},[_vm._v(" Create ")])],1),_c('vs-form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"w-1/2 p-5"},[_c('div',[_c('h5',[_vm._v("Company Info")])]),_c('div',{staticClass:"w-full my-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('name') && _vm.organization.name != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('name') ? true : false,"name":"name","data-vv-as":"name","label":"Organization Name","icon-no-border":"","icon":"icon icon-user","icon-pack":"feather","danger-text":_vm.errors.first('name')},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}})],1),(_vm.$isAuRegion())?_c('div',{staticClass:"w-full my-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: /^\d{11}$/,
          }),expression:"{\n            required: true,\n            regex: /^\\d{11}$/,\n          }"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('abn') && _vm.organization.abn != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('abn') ? true : false,"name":"abn","danger-text":_vm.errors.first('abn'),"data-vv-as":"abn","label":"ABN","icon-no-border":""},model:{value:(_vm.organization.abn),callback:function ($$v) {_vm.$set(_vm.organization, "abn", $$v)},expression:"organization.abn"}})],1):_vm._e(),_c('div',[_c('h5',[_vm._v("Organisation Settings")])]),_c('div',[_c('p',[_vm._v(" Organisation settings are applied to all clinic workspaces associated to this Organisation. These can be changed at a clinic level from clinic settings. ")])]),_c('div',{staticClass:"w-full my-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: /(^\w.*@\w+\.\w)/,
          }),expression:"{\n            required: true,\n            regex: /(^\\w.*@\\w+\\.\\w)/,\n          }"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('email') && _vm.organization.email != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('email') ? true : false,"name":"email","data-vv-as":"email","label":"Monthly Treatment Invoice Email(s)","danger-text":_vm.errors.first('email')},model:{value:(_vm.organization.email),callback:function ($$v) {_vm.$set(_vm.organization, "email", $$v)},expression:"organization.email"}})],1),_c('div',{staticClass:"w-full my-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: /^\d*\.?\d*$/,
          }),expression:"{\n            required: true,\n            regex: /^\\d*\\.?\\d*$/,\n          }"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('consultationFee') &&
            _vm.organization.consultationFee != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('consultationFee') ? true : false,"name":"consultationFee","danger-text":_vm.errors.first('consultationFee'),"data-vv-as":"consultation fee","label":"Treatment Consultation Fee (default is $25.00)","icon-no-border":""},model:{value:(_vm.organization.consultationFee),callback:function ($$v) {_vm.$set(_vm.organization, "consultationFee", $$v)},expression:"organization.consultationFee"}})],1),_c('div',{staticClass:"w-full my-5"},[_c('label',[_vm._v("Enable/Disable BYO Organization")]),_c('vs-switch',{attrs:{"name":"isByo","data-vv-as":"BYO Organization","label":"Enable/Disable BYO Organization"},model:{value:(_vm.organization.isByo),callback:function ($$v) {_vm.$set(_vm.organization, "isByo", $$v)},expression:"organization.isByo"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }